// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconUpload(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Select";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M12.17 5.311c-0.221 0-0.437 0.020-0.647 0.054-0.525-1.716-2.15-2.966-4.076-2.966-2.351 0-4.256 1.862-4.256 4.159 0 0.205 0.016 0.406 0.046 0.605-0.113-0.014-0.226-0.022-0.343-0.022-1.598 0-2.894 1.266-2.894 2.828s1.295 2.83 2.894 2.83h3.506v-3.2h-2l3.6-4 3.6 4h-2v3.2h2.57c2.114 0 3.83-1.677 3.83-3.744 0-2.069-1.715-3.745-3.83-3.745z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconUpload;

export {
  make ,
}
/* Icon Not a pure module */
